

































































import Vue from "vue";
import Requester from "@/requester/index";
import { TokenSenhaAlterarFormData } from "../../../../back/src/types/formData";

import _ from "lodash";
export default Vue.extend({
  name: "UsersPassword",

  data() {
    return {
      loading: {
        submitForm: false,
      },
      showPassword: false,
      showPasswordConfirmation: false,
      form: {
        senha: null,
        senha2: null,
      },
      rules: {
        required: [(v: string) => !!v || ""],
      },
    };
  },
  computed: {
    appendIconPassword() {
      return this.showPassword ? "mdi-eye-off" : "mdi-eye";
    },
    appendIconPasswordConfirmation() {
      return this.showPasswordConfirmation ? "mdi-eye-off" : "mdi-eye";
    },
    formAuthLogin(): Vue & { validate: () => boolean } {
      return this.$refs.formRecovery as Vue & {
        validate: () => boolean;
      };
    },
  },
  methods: {
    confirmPassword() {
      if (!this.form.senha2)
        return "É necessário informar a confirmação de senha.";
      if (this.form.senha2 !== this.form.senha)
        return "As senhas precisam ser iguais.";
      return true;
    },
    submitFormDebounce: _.debounce(async function () {
      await this.submitForm();
    }, 200),
    async submitForm() {
      if (!this.formAuthLogin.validate()) {
        return;
      }
      this.loading.submitForm = true;
      const formData: TokenSenhaAlterarFormData = {
        senha: this.form.senha,
        token: this.$route.query.token,
      };
      try {
        await Requester.users.public.recuperaSenhaPorToken(formData);
        this.$root.$emit("showToast", {
          text: "Senha alterada com sucesso!",
          color: "success",
        });
        this.$router.push({ name: "Login" });
      } catch (err) {
        this.$root.$emit("showToast", {
          text: err.response?.data ?? err.toString(),
          color: "error",
        });
      } finally {
        this.loading.submitForm = false;
      }
    },
  },
});
